<template>
	<v-menu
		:disabled="disabled"
		ref="menu"
		nudge-bottom="45"
		v-model="menu"
		left
		:close-on-content-click="false"
		transition="scale-transition"
		min-width="auto">
		<template v-slot:activator="{ on, attrs }">
			<v-hover v-slot="{ hover }">
				<div
					style="position: relative"
					v-bind="attrs"
					v-on="on">
					<v-text-field
						:rules="rules"
						@click="onClick"
						:disabled="disabled"
						v-model="formattedValue"
						:label="title"
						prepend-icon="mdi-calendar"
						readonly></v-text-field>
					<v-slide-y-transition>
						<v-icon
							@click.stop.prevent="$emit('input', undefined)"
							color="primary"
							class="defaultbg"
							v-if="value && hover"
							style="
								position: absolute;
								top: 15px;
								right: -1px;
								border-radius: 5px 5px 0px 0px;
							">
							mdi-close
						</v-icon>
					</v-slide-y-transition>
				</div>
			</v-hover>
		</template>
		<v-tabs
			fixed-tabs
			v-model="activeTab">
			<v-tab key="calendar">
				<slot name="dateIcon">
					<v-icon>event</v-icon>
				</slot>
			</v-tab>
			<v-tab
				key="timer"
				:disabled="dateSelected">
				<slot name="timeIcon">
					<v-icon>access_time</v-icon>
				</slot>
			</v-tab>
			<v-tab-item key="calendar">
				<v-date-picker
					class="ma-0"
					:min="minDate"
					:max="maxDate"
					v-model="date"
					v-bind="datePickerProps"
					@input="showTimePicker"
					no-title></v-date-picker>
			</v-tab-item>
			<v-tab-item key="timer">
				<v-time-picker
					ref="timer"
					class="ma-0"
					:min="minTime"
					:max="maxTime"
					v-model="time"
					v-bind="timePickerProps"
					format="24hr"
					@click:minute="updateVal"></v-time-picker>
			</v-tab-item>
		</v-tabs>
	</v-menu>
</template>
<script>
import { format } from "date-fns";
import { toLocalDate } from "../../constants/helpers";

export default {
	props: {
		min: {
			type: Date,
			default: undefined,
		},
		max: {
			type: Date,
			default: undefined,
		},
		createdNow: {
			type: Boolean,
			default: true,
		},
		value: { type: undefined },
		datePickerProps: { type: undefined },
		timePickerProps: { type: undefined },
		disabled: { type: undefined },
		title: { type: undefined },
		rules: {
			type: undefined,
		},
	},

	data() {
		return {
			menu: false,
			activeTab_: 0,
			forceBuild: true,
			date: this.value ? format(new Date(this.value), "yyyy-MM-dd") : null,
			minClone: this.min,
			time_: this.value ? this.value : null, //this.value ? format(new Date(this.value), "HH:mm") : null,
			formattedValue_: this.value,
		};
	},

	computed: {
		activeTab: {
			get() {
				return this.activeTab_;
			},
			set(val) {
				this.activeTab_ = val;
				setTimeout(() => {
					this.$refs.menu?.updateDimensions();
					// this.$refs.menu.activate();
				}, 220);
			},
		},
		time: {
			get() {
				const min = this.min ?? 0;
				if (!this.time_) {
					return undefined;
				}
				const res = Math.max(new Date(this.time_), new Date(min));
				if (isNaN(res)) {
					return undefined;
				}
				return format(new Date(res), "HH:mm");
			},
			set(val) {
				if (this.date && val) this.time_ = `${this.date}T${val}`;
			},
		},
		minTime() {
			if (!this.minClone) return undefined;
			if (new Date(this.date) > new Date(this.minDate)) return undefined;

			return format(new Date(this.minClone), "HH:mm");
			// return this.minClone?.toISOString().slice(11, 19);
		},
		maxTime() {
			if (!this.max) return undefined;
			if (new Date(this.date) < new Date(this.maxDate)) return undefined;

			return this.max ? format(this.max, "HH:mm") : undefined;
			// return this.max?.toISOString().slice(11, 19);
		},
		minDate() {
			return this.min ? format(this.min, "yyyy-MM-dd") : undefined;
			// return this.minClone?.toISOString().slice(0, 10);
		},
		maxDate() {
			return this.max ? format(this.max, "yyyy-MM-dd") : undefined;
			// return this.max?.toISOString().slice(0, 10);
		},
		formattedValue: {
			get() {
				if (!this.formattedValue_) return "";

				return `${toLocalDate(new Date(this.formattedValue_))}`;
			},
			set(val) {
				this.formattedValue_ = new Date(val);
			},
		},
		dateSelected() {
			return this.date !== null;
		},
	},
	methods: {
		async onClick() {
			if (this.createdNow) {
				this.minClone = Math.min(this.min, new Date());
				if (this.date <= format(new Date(), "yyyy-MM-dd")) {
					if (this.time < format(new Date(), "HH:mm")) {
						this.time = format(new Date(), "HH:mm");
					}
				}

				if (this.date && this.time)
					this.formattedValue = new Date(`${this.date}T${this.time}`);
			}

			await this.$nextTick();
			this.$emit("click");
		},
		async showTimePicker() {
			this.activeTab = 1;
		},
		async updateVal() {
			await this.$nextTick();

			if (!this.date || !this.time) {
				return;
			}
			this.$emit("input", new Date(`${this.date}T${this.time}Z`));
			this.formattedValue = new Date(`${this.date}T${this.time}`);
			this.menu = false;
			this.activeTab = 0;
			if (this.$refs.timer) this.$refs.timer.selectingHour = true;
		},
	},
};
</script>

<style scoped>
.v-menu__content {
	transition: all 0.3s !important;
}

.v-text-field__slot > .v-label {
	pointer-events: none;
}

.v-text-field__slot > .v-input__slot {
	pointer-events: none;
}
</style>
